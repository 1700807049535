@tailwind base;
@tailwind components;
@tailwind utilities;

:root{

    --blue : #349AEA;
    --blue-dark: #13629E;
    --red: #F2120C;
    --green: #E7EB1C;
    --grey : #878787;


    --summer-sky : #38BFF0;
    --laser : #A7DCF8;
    --nero : #1F1F1F;

    --white : #ffffff;
    --main-box : var(--white);

    --main-color: var(--summer-sky);
    --secondary-color: var(--laser);
    --third-color: var(--red);
    --back-dark-color: var(--nero);
}

@font-face {
    font-family: 'Acumin Pro';
}

*{
    font-family: Acumin;
}

.h1{
    font-size: 40pt;
    line-height: 1.35;
}

.h2{
    font-size: 15pt;
    line-height: 1.35;

    font-weight: 700;
}
.body-c{

}




.box-main{
    background-color: var(--main-box);
}
/*mt-4 px-4 py-2 bg-blue-600 text-white text-sm uppercase font-medium rounded hover:bg-blue-500 focus:outline-none focus:bg-blue-500*/

.menu-nav{
display: flex;
    align-items: center;
    list-style-type: none;
}

.menu-item{
    font-weight: bold;
    margin: 0 15px;
}
.menu-link{
    align-items: center;
    justify-content: center;
    display: flex;
    text-transform: uppercase;
    padding: 5px 0;
    position: relative;
    overflow: hidden;
    transition: .25s;
}

.menu-link:before{
    content: '';
    position: absolute;
    top: 0;
    right: 100%;
    width: 50%;
    height: 2px;
    background: #78c7d2;
    transition: .5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.menu-link:after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 100%;
    width: 50%;
    height: 2px;
    background: #78c7d2;
    transition: .5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.menu-link:hover{
    color: #000000;
}
.menu-link:hover:before{
    right: 0;
}
.menu-link:hover:after{
    left: 0;
}







body {
    margin: 0;
    height: 100%;
    background-image: linear-gradient(to top, var(--grey) 0%, var(--white) 65%, var(--white) 100%);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.button_container {
    position: absolute;
    left: 0;
    right: 0;
    top: 30%;
}

.description, .link {
    font-family: 'Amatic SC', cursive;
    text-align: center;
}

.description {
    font-size: 35px;
}

.banner_btn {
    border: none;
    display: block;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    outline: none;
    overflow: hidden;
    position: relative;
    color: var(--white);
    background-color: var(--back-dark-color);
    padding: 17px 60px;
    margin: 0 auto;
    box-shadow: 0 5px 15px rgba(0,0,0,0.20);
}

.banner_btn span {
    position: relative;
    z-index: 1;
}

.banner_btn:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 490%;
    width: 140%;
    background: var(--main-color);
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    -webkit-transform: translateX(-98%) translateY(-25%) rotate(45deg);
    transform: translateX(-98%) translateY(-25%) rotate(45deg);
}

.banner_btn:hover:after {
    -webkit-transform: translateX(-9%) translateY(-25%) rotate(45deg);
    transform: translateX(-9%) translateY(-25%) rotate(45deg);
}

.link {
    font-size: 20px;
    margin-top: 30px;
}

.link a {
    color: #000;
    font-size: 25px;
}

.button_secondary{
    font-weight: bold;
    color: var(--white);
    background-color: var(--main-color);
    border: 2px solid black;
    line-height: 1;
    margin: 0.5em;
    padding: 1em 2em;
    transition: 0.25s;
}

.button_secondary:hover,
.button_secondary:focus {
    background-color: var(--red);
    box-shadow: 0 0.5em 0.5em -0.4em var(--red);
    color: var(--white);
    transform: translateY(-0.25em);
}